
    .fill-item {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        .fill-input {
            width: 300px;
            padding: 0 10px;
        }
    }
